<template>
  <div
    id="app"
    :lang="lang"
    :class="{
      'no-header': !isShowHeader,
      'no-footer': !isShowFooter,
      ios: $isIos(),
    }"
  >
    <intersect
      @enter="$store.commit('setIsShowGoTopButton', false)"
      @leave="$store.commit('setIsShowGoTopButton', true)"
    >
      <div class="intersect-top"></div>
    </intersect>

    <template v-if="!loadingListIncludeSiteInfo">
      <template v-if="isShowHeader">
        <component :is="AppHeader"></component>
        <component :is="AppHeaderSub" v-if="(!userIsLoggedIn && this.$route.name === 'Home') || userIsLoggedIn" />
      </template>

      <div class="main">
        <router-view />
      </div>

      <component :is="AppFooter" v-if="isShowFooter" />
    </template>

    <AppLoading v-show="loadingList.length > 0" />

    <div v-if="userIsLoggedIn">
      <component :is="ModalTransfer" v-if="modalTransferIsShow" @close="$store.dispatch('closeModalTransfer')" />
      <component
        :is="ModalWinWheel"
        v-if="modalWinWheelIsShow"
        @close="$store.commit('setModalWinWheelIsShow', false)"
      />
      <component
        :is="ModalRedEnvelope"
        v-if="modalRedEnvelopeIsShow"
        @close="$store.commit('setModalRedEnvelopeIsShow', false)"
      />
    </div>

    <component :is="AppLotteryButtonBlock" v-show="isShowLotteryButton" />
    <component :is="AppGoTopButton" v-show="isShowGoTop" />
    <ModalMessageSiteBlocked
      v-if="modalMessageSiteBlockedIsShow"
      @close="$store.commit('setModalMessageSiteBlockedIsShow', false)"
    />
    <ModalMessageApkDownload
      v-if="modalMessageApkDownloadIsShow"
      @close="$store.commit('setModalMessageApkDownloadIsShow', false)"
    />

    <component
      :is="ModalForgetPassword"
      @close="$store.dispatch('closeModalForgetPassword')"
      v-if="modalForgetPasswordIsShow"
    />
    <component :is="ModalContact" v-if="modalContactIsShow" @close="$store.dispatch('closeModalContact')" />
    <component
      :is="ModalAlert"
      :type="modalAlertOption.type"
      :title="modalAlertOption.title"
      :text="modalAlertOption.text"
      :notice="modalAlertOption.notice"
      @close="$store.dispatch('closeModalAlert')"
      v-if="modalAlertIsShow"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import mixinStyleLoader from '@/mixins/_styleLoader';
import mixinPwa from '@/mixins/pwa';
import Intersect from 'vue-intersect';
import mixinPreloadImage from '@/mixins/_preloadImage';

export default {
  name: 'App',
  mixins: [mixinStyleLoader, mixinPwa, mixinPreloadImage],
  components: {
    AppLoading: () => import('@/components/AppLoading'),
    AppGoTopButton: () => import('@/components/AppGoTopButton'),
    // ModalTransfer: () => import('@/components/ModalTransfer'),
    // ModalWinWheel: () => import('@/components/lottery/ModalWinWheel'),
    // ModalRedEnvelope: () => import('@/components/lottery/ModalRedEnvelope'),
    ModalMessageSiteBlocked: () => import('@/components/ModalMessageSiteBlocked'),
    ModalMessageApkDownload: () => import('@/components/ModalMessageApkDownload'),
    Intersect,
  },
  computed: {
    ...mapGetters([
      'lang',
      'loadingList',
      'loadingListIncludeSiteInfo',
      'modalTransferIsShow',
      'modalWinWheelIsShow',
      'modalRedEnvelopeIsShow',
      'modalMessageSiteBlockedIsShow',
      'modalMessageApkDownloadIsShow',
      'isShowGoTopButton',
      'siteSetting',
      'siteFullCss',
      'siteIsActive',
      'siteIsSpare',
      'siteEnableSpareDomain',
      'siteID',
      'userIsLoggedIn',
      'userAccount',
      'modalForgetPasswordIsShow',
      'modalContactIsShow',
      'modalAlertIsShow',
      'modalAlertOption',
    ]),
    isShowHeader() {
      return this.$route.meta.header !== false;
    },
    isShowFooter() {
      return this.$route.meta.footer !== false;
    },
    isShowLotteryButton() {
      return this.$route.meta.lottery !== false && this.userIsLoggedIn && this.siteIsActive;
    },
    isShowGoTop() {
      return this.$route.meta.goTop !== false && this.isShowGoTopButton;
    },
    AppHeader() {
      return () => import(`@/${this.siteSetting.components.app.AppHeader}`);
    },
    AppHeaderSub() {
      if (!this.siteSetting.components.app.AppHeaderSub) {
        return '';
      }
      return () => import(`@/${this.siteSetting.components.app.AppHeaderSub}`);
    },
    AppFooter() {
      if (!this.siteSetting.components.app.AppFooter) {
        return '';
      }
      return () => import(`@/${this.siteSetting.components.app.AppFooter}`);
    },
    AppLotteryButtonBlock() {
      if (this.siteSetting.components.app.AppLotteryButtonBlock) {
        return () => import(`@/${this.siteSetting.components.app.AppLotteryButtonBlock}`);
      }
      return '';
    },
    AppGoTopButton() {
      if (this.siteSetting.components.app.AppGoTopButton) {
        return () => import(`@/${this.siteSetting.components.app.AppGoTopButton}`);
      }
      return '';
    },
    ModalTransfer() {
      if (this.siteSetting.components.app.ModalTransfer) {
        return () => import(`@/${this.siteSetting.components.app.ModalTransfer}`);
      }
      return '';
    },
    ModalWinWheel() {
      if (this.siteSetting.components.app.ModalWinWheel) {
        return () => import(`@/${this.siteSetting.components.app.ModalWinWheel}`);
      }
      return '';
    },
    ModalRedEnvelope() {
      if (this.siteSetting.components.app.ModalRedEnvelope) {
        return () => import(`@/${this.siteSetting.components.app.ModalRedEnvelope}`);
      }
      return '';
    },
    //* 橫版
    ModalContact() {
      if (this.siteSetting.components.app.ModalContact) {
        return () => import(`@/${this.siteSetting.components.app.ModalContact}`);
      }
      return '';
    },
    ModalAlert() {
      if (this.siteSetting.components.app.ModalAlert) {
        return () => import(`@/${this.siteSetting.components.app.ModalAlert}`);
      }
      return '';
    },
    ModalForgetPassword() {
      if (this.siteSetting.components.forgetPassword.ModalForgetPassword) {
        return () => import(`@/${this.siteSetting.components.forgetPassword.ModalForgetPassword}`);
      }
      return '';
    },
  },
  watch: {
    lang() {
      if (this.userIsLoggedIn) {
        this.$store.dispatch('user/getInfo');
        this.$store.dispatch('user/getPointInfo');
      }

      const requestDataProductList = { Lang: this.lang };
      this.$store.dispatch('product/getList', requestDataProductList);

      const requestDataSeo = { Lang: this.lang };
      this.$store.dispatch('site/getSeoInfo', requestDataSeo);
    },
    userIsLoggedIn: {
      immediate: true,
      async handler(val) {
        let event = 'login';
        let h3webuserid = '';
        if (val === true) {
          h3webuserid = this.$md5(this.siteID + this.userAccount);
        }
        window.dataLayer.push({ event, h3webuserid });
      },
    },
  },
  mounted() {
    console.log('[SiteFullCss]', this.siteFullCss);

    this.importStyleByFilename('_layout');
    this.importStyleByFilename('header');
    this.importStyleByFilename('footer');
    // this.importStyleByFilename('pagination');

    //* 確認是否維護
    if (!this.siteIsActive && this.$route.name !== 'Maintenance') {
      this.$router.replace({ name: 'Maintenance' });
      return;
    }

    //* 確認是否要顯示假電郵(未登入一律轉至假電郵登入頁)
    if (this.siteIsSpare === true && this.siteEnableSpareDomain === true && this.userIsLoggedIn === false) {
      this.$router.replace({ name: 'SignIn' });
      return;
    }

    //* PWA
    this.initPwa();
  },
};
</script>
