import { clearCache } from '@/utils/cache';
import { apiGetVersion } from '@/api/file';
import { localStorageGetVersion, localStorageSetVersion } from '@/utils/localStorage';

export async function checkVersion() {
  const version = localStorageGetVersion();
  const versionNew = await apiGetVersion();

  if (!version) {
    return localStorageSetVersion(versionNew);
  }

  if (version !== versionNew) {
    localStorageSetVersion(versionNew);
    await clearCache();
    // if (msg) {
    //   window.alert(msg);
    // }
    window.location.reload();
  }
}
