export function changeViewportWidth(width) {
  const viewport = document.querySelector('meta[name=viewport]');
  viewport.content = `width=${width}, user-scalable=no`;
}

export function requestFullScreen(el = document.documentElement) {
  console.log('requestFullScreen');
  const requestMethod =
    el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullScreen;
  if (requestMethod) {
    requestMethod.call(el, { navigationUI: 'hide' });
  }
}

export function cancelFullScreen(el = document) {
  console.log('cancelFullScreen');
  const requestMethod =
    el.cancelFullScreen ||
    el.webkitCancelFullScreen ||
    el.mozCancelFullScreen ||
    el.exitFullscreen ||
    el.webkitExitFullscreen;
  if (requestMethod) {
    requestMethod.call(el);
  }
}
