import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import {
  LANG_DEFAULT,
  OFFLINE_MESSAGE,
  SITE_STYLE_CLASS_LIST,
  SITE_DEFAULT_STYLE_CLASS,
  SITE_DEFAULT_STYLE_TYPE,
  PHONE_COUNTRY_LIST,
} from '@/settings';
import { cookieGetLang } from '@/utils/cookie';
import {
  localStorageGetToken,
  localStorageGetPublicKey,
  localStorageGetIsLoggedIn,
  localStorageGetIsPreview,
} from '@/utils/localStorage';
//* utils
import { isObjEmpty, deepClone, getObjectValueByDotString } from '@/utils/object';
import { shuffleArray } from '@/utils/array';
import { isStandaloneMode, isIos, getUserDevice } from '@/utils/device';
import { changeViewportWidth } from '@/utils/document';
import { checkVersion } from '@/utils/version';
import { checkSiteActive, checkSitePreview } from '@/utils/site';

Vue.config.productionTip = false;

console.log(process.env.VUE_APP_ENV || process.env.NODE_ENV);
console.log('update 2022-12-15 13:50');
console.log(window.navigator.userAgent);

//* 離線通知
if (!window.navigator.onLine) {
  console.log(OFFLINE_MESSAGE);
}

//* 取得目前語系
const lang = cookieGetLang() || LANG_DEFAULT;

//* 載入語系
store.commit('setLang', lang);

//* 取得語系列表
store.dispatch('getLangList');

//* 取得 SEO 資訊
const requestDataSeo = { Lang: lang };
store.dispatch('site/getSeoInfo', requestDataSeo).then(() => {
  //* 設置 window.mdevice (Google Analytics)
  window.dataLayer.push({ mdevice: getUserDevice() });
});

//* 取得是否登入 (Cookie)
const isLoggedIn = localStorageGetIsLoggedIn();
store.commit('user/setIsLoggedIn', isLoggedIn);

//* 取得使用者資訊 (已登入)
if (isLoggedIn) {
  store.dispatch('user/getInfo');
}

/**
 ** 取得公鑰 & token (； 頁面取得；已登入 )
 ** 1. 優先從 Cookie 取得
 ** 2. 未登入: Cookie 沒有 => 只於 Login、Register 頁面取得
 ** 3. 已登入: Cookie 沒有 => 不管哪個頁面，直接取得
 */
if (localStorageGetToken() && localStorageGetPublicKey()) {
  store.commit('user/setToken', localStorageGetToken());
  store.commit('user/setPublicKey', localStorageGetPublicKey());
} else if (isLoggedIn) {
  store.dispatch('user/getTokenAndPublicKey');
}

//* 取得遊戲館列表
if (isLoggedIn) {
  store.dispatch('product/postList');
} else {
  const requestDataProductList = { Lang: lang };
  store.dispatch('product/getList', requestDataProductList);
}

//* CSS
import '../node_modules/normalize.css/normalize.css'; //* ^3.0.2
import './styles/global/base.scss';

//* JS
import './router/permission'; //* 路徑權限
import './api/interceptors.js'; //* 攔截器
import i18n from '@/i18n-lazy'; //* 語言載入
import '@/utils/vee-validate.js'; //* 載入 vee-validate 規則
import { getQueryValueByKey, checkQueryByKey } from '@/utils/url';

//* API
import { apiKeepUserOnline } from '@/api/user';

//* dayjs
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

import numeral from 'numeral';
import md5 from 'blueimp-md5';
import idMapper from '@/idMapper';

//* set Vue.prototype
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$numeral = numeral;
Vue.prototype.$md5 = md5;
Vue.prototype.$idMapper = idMapper;

Vue.prototype.$env = process.env.VUE_APP_ENV || process.env.NODE_ENV;
Vue.prototype.$isLocalhost = window.location.hostname === 'localhost';
Vue.prototype.$isObjEmpty = isObjEmpty;
Vue.prototype.$deepClone = deepClone;
Vue.prototype.$getObjectValueByDotString = getObjectValueByDotString;
Vue.prototype.$shuffleArray = shuffleArray;
Vue.prototype.$isStandaloneMode = isStandaloneMode;
Vue.prototype.$isIos = isIos;
Vue.prototype.$device = getUserDevice();

//* Vue Global Component
import VueScrollTo from 'vue-scrollto'; //* 此 Library 只能註冊全域
Vue.use(VueScrollTo);

//* vue-tel-input
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
const vueTelInputOptions = {
  defaultCountry: 66,
  autoDefaultCountry: false,
  onlyCountries: PHONE_COUNTRY_LIST,
  dropdownOptions: { showDialCodeInList: true, showDialCodeInSelection: true, showFlags: true },
};
Vue.use(VueTelInput, vueTelInputOptions);

(async () => {
  //* 取得站台資訊(Code: 推廣碼，若有推廣碼，則將轉址至首頁)
  const proxyCode = getQueryValueByKey('proxyCode');
  const requestDataSiteInfo = { DeviceType: 1, Code: proxyCode };
  if (isLoggedIn || store.getters.siteIsPreview === true) {
    requestDataSiteInfo.Lang = lang;
    await store.dispatch('site/postInfo', requestDataSiteInfo);
  } else {
    await store.dispatch('site/getInfo', requestDataSiteInfo);
  }

  //* 預設版號、版型 (若是傳來的版型不在列表內)
  if (!SITE_STYLE_CLASS_LIST.includes(store.getters.siteCssClass)) {
    console.log('SET DEFAULT', SITE_DEFAULT_STYLE_CLASS);
    store.commit('site/setInfoStyle', { siteClass: SITE_DEFAULT_STYLE_CLASS, siteType: SITE_DEFAULT_STYLE_TYPE });
  }

  //* 設置站台設定檔
  await store.dispatch('site/loadSetting');

  //* 心跳，剛進來也要執行一次
  //* document.visibilityState & document.hasFocus()，前者只要頁面是停留此頁就是 visible，後者一定要 focus 在頁面上才會是 true
  setInterval(
    (function KeepUserOnline() {
      if (document.visibilityState === 'visible' && store.getters.userIsLoggedIn) {
        apiKeepUserOnline();
      }
      return KeepUserOnline;
    })(),
    50000
  );

  //* 手動設置 style && setting && landscape
  if (process.env.VUE_APP_ENV === 'development' && window.location.hostname === 'localhost') {
    const siteClass = 'Z';
    const siteType = '01';
    const isLandscape = false;
    console.log('手動設置', siteClass, siteType, isLandscape);
    //* style
    store.commit('site/setInfoStyle', { siteClass, siteType });

    //* setting
    await store.dispatch('site/loadSetting', siteClass);

    //* landscape
    store.state.site.info.IsPortrait = !isLandscape;

    //* lang
    // store.dispatch('changeLang', 'my-mm');
  }

  //* 是否顯示 apk download message (網址有 ?pwa 的話)
  if (checkQueryByKey('pwa')) {
    store.commit('setModalMessageApkDownloadIsShow', true);
  }

  //* 設置 Meta
  //* manifest 用環境變數來實現 (因為有 GET API)
  // document.querySelector('link[rel=manifest]').setAttribute('href', store.getters.siteManifestUrl);
  document.querySelector('link[rel=icon]').setAttribute('href', store.getters.siteFaviconUrl);

  //* 設置 IOS apple-mobile-web-app-title
  // document.querySelector('#apple-title').setAttribute('content', 'AppTitle');

  //* 設置 IOS apple-touch-icon
  document.querySelector('#apple-touch-icon').setAttribute('href', store.getters.siteAppIconUrl('192x192'));

  //* 設置 IOS apple-touch-startup-image
  document.querySelector('#apple-startup-image-750x1334').setAttribute('href', store.getters.siteIOSUrl('750x1334'));
  document.querySelector('#apple-startup-image-828x1792').setAttribute('href', store.getters.siteIOSUrl('828x1792'));
  document.querySelector('#apple-startup-image-1125x2436').setAttribute('href', store.getters.siteIOSUrl('1125x2436'));
  document.querySelector('#apple-startup-image-1242x2208').setAttribute('href', store.getters.siteIOSUrl('1242x2208'));

  //* Open Graph
  //* image 尺寸:200 ~ 1200(超過的話會被等比例縮圖)，1.91:1 或 1:1 (FB需要 1.91:1 時，會自己裁切)
  // document.querySelector('meta[property="og:url"]').setAttribute('content', 'https://m.caza7.re888show.com');
  // document.querySelector('meta[property="og:title"]').setAttribute('content', 'Title Title');
  // document.querySelector('meta[property="og:description"]').setAttribute('content', 'Description Description');
  // document.querySelector('meta[property="og:site_name"]').setAttribute('content', store.getters.siteName);
  // document
  //   .querySelector('meta[property="og:image"]')
  //   .setAttribute('content', 'https://img.magiclen.org/albums/facebook-thumbnail/shot-03.png');

  //* prerender-spa-plugin
  // document.dispatchEvent(new Event('custom-render-trigger'));

  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    beforeCreate() {
      //* 若網址有推廣碼，則轉址至首頁
      if (proxyCode) {
        this.$router.replace({ name: 'Home' });
      }
    },
    async mounted() {
      //* 檢查 Version (目前設置於 public/version)
      checkVersion(this.$t('alert.versionUpdate'));

      //* 檢查網域是否正常 => 轉址網域不是空值 && 非此網域 => 顯示轉址視窗
      const resultSiteActive = await checkSiteActive(store.getters.siteID);
      if (resultSiteActive.Code === 200 && resultSiteActive.RetObj !== window.location.hostname) {
        store.commit('site/setDomainRedirect', resultSiteActive.RetObj);
        store.commit('setModalMessageSiteBlockedIsShow', true);
      }

      //* 從 localStorage 設置預覽模式
      store.commit('site/setIsPreview', localStorageGetIsPreview());
      //* 檢查是否要開啟預覽模式
      const resultPreview = await checkSitePreview();
      if (resultPreview.Code === 200 && resultPreview.RetObj === true) {
        store.commit('site/setIsPreview', true);
        router.replace({ name: 'Home', query: {} }).catch(() => {});
      }

      //* 橫版
      if (store.getters.siteIsLandscape) {
        changeViewportWidth('device-width');
      }
    },
  }).$mount('#app');
})();
