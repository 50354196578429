export async function clearCache() {
  if (!window.caches) {
    return;
  }
  const cacheKeyList = await window.caches.keys();
  cacheKeyList.forEach(key => caches.delete(key));
}

export async function getCacheValueListByKey(key) {
  const ce = await window.caches.open(key);
  const res = await ce.matchAll();
  const result = res.map(el => el.url);
  return result;
}
