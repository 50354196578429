import { mapGetters } from 'vuex';
import { loadImage } from '@/utils/image';
import { getCacheValueListByKey } from '@/utils/cache';
import { sleep } from '@/utils/sleep';
import { apiGetBannerList } from '@/api/banner';
import { apiGetPromotionList } from '@/api/promotion';
import { apiGetGameLobbyGameList } from '@/api/game';
import { localStorageGetVisitDate, localStorageSetVisitDate } from '@/utils/localStorage';

export default {
  name: 'MixinPreloadImage',
  computed: {
    ...mapGetters([
      'siteFullCss',
      'lang',
      'siteIsLandscape',
      'productList',
      'productClassifyList',
      'productListByClassify',
      'siteProductImage',
      'siteProductImagePortrait',
      'siteProductImagePortraitSmall',
      'siteProductImagePortraitDefault',
      'siteProductImagePortraitDefaultSmall',
    ]),
  },
  data() {
    return {
      //* local-static-file-cache
      // localCacheValueList: [],

      //* cors-static-file-cache
      corsCacheValueList: [],

      //* api-cache
      apiCacheValueList: [],

      // timeout: 3000,
      timeout: 0,
    };
  },
  watch: {
    lang() {
      if (this.$isLocalhost) {
        return;
      }

      this.loadAll();
    },
  },
  async mounted() {
    //* 一天預載一次，用 localStorage 確認 (visitDate)
    if (this.$dayjs().isSame(localStorageGetVisitDate(), 'day')) {
      return;
    }
    localStorageSetVisitDate(this.$dayjs().format('YYYY-MM-DD'));

    // if (this.$isLocalhost) {
    //   return;
    // }
    // this.localCacheValueList = await getCacheValueListByKey('local-static-file-cache');
    this.corsCacheValueList = await getCacheValueListByKey('cors-static-file-cache');
    this.apiCacheValueList = await getCacheValueListByKey('api-cache');

    //* 去掉 window.location.origin
    // this.localCacheValueList = this.localCacheValueList.map(item => item.replace(window.location.origin, ''));

    //* 載入圖片 (首頁、優惠活動、電子遊戲列表(2、6、7)) (設置延遲)
    window.setTimeout(() => {
      this.loadAll();
    }, 500);
  },
  methods: {
    async loadImageBannerList() {
      console.log('load image', 'Banner');
      const requestData = { Lang: this.lang };
      const result = await apiGetBannerList(requestData);
      if (result.Code === 200) {
        result.RetObj.forEach(item => {
          const url = item.Lst_ImgUrl;
          if (this.corsCacheValueList.includes(url)) {
            return;
          }
          loadImage(url);
        });
      }
    },
    async loadImagePromotion() {
      console.log('load image', 'Promotion');
      const requestData = { Lang: this.lang };
      const result = await apiGetPromotionList(requestData);
      if (result.Code === 200) {
        result.RetObj.PromotionList.forEach(item => {
          const url = item.Lst_ImageUrl;
          if (this.corsCacheValueList.includes(url)) {
            return;
          }
          loadImage(url);
        });
      }
    },
    async loadImageGameList(classify) {
      console.log('load image', 'GameLobby', classify);
      this.productListByClassify(classify).forEach(async item => {
        if (
          this.$route.name === 'GameLobby' &&
          Number(this.$route.params.id) === item.Lst_Product_id &&
          Number(this.$route.params.key) === item.Lst_Proxy_Product_Key
        ) {
          return;
        }

        const Tag = item.Lst_Product_id + '-' + item.Lst_Proxy_Product_Key;
        const requestData = {
          Tag,
          GameClassify: classify,
          Category: '',
          GameName: '',
          IsLike: 0,
          Entrance: 0,
          Page: 1,
          Lang: this.lang,
        };

        const result = await apiGetGameLobbyGameList(requestData);
        if (result.Code === 200) {
          result.RetObj.JsonGameList.forEach(game => {
            const url = game.imagePath;
            if (this.corsCacheValueList.includes(url)) {
              return;
            }
            loadImage(url);
          });
        }
      });
    },
    loadImageProductItem() {
      console.log('load image', 'HomeProductItem');
      this.productList.forEach(item => {
        const img = this.siteProductImage(item);
        loadImage(img);

        //* development
        // const imgPortrait = this.siteProductImagePortrait(item);
        // const imgPortraitDefault = this.siteProductImagePortraitDefault(item);

        // if (!this.corsCacheValueList.includes(imgPortrait)) {
        //   loadImage(imgPortrait);
        // }
        // if (!this.corsCacheValueList.includes(imgPortraitDefault)) {
        //   loadImage(imgPortraitDefault);
        // }
      });
    },
    async loadAll() {
      await sleep(this.timeout);
      const route = this.$route.name;
      // const params = this.$route.params;

      if (route !== 'Home') {
        this.loadImageBannerList();
        this.loadImageProductItem();
      }

      if (route !== 'Promotion') {
        this.loadImagePromotion();
      }

      // if (route === 'Home') {
      //   window.setTimeout(() => {
      //     this.loadImagePromotion();
      //     this.loadImageGameList(2);
      //     this.loadImageGameList(6);
      //     this.loadImageGameList(7);
      //   }, this.timeout);
      // } else if (route === 'Promotion') {
      //   window.setTimeout(() => {
      //     this.loadImageBannerList();
      //     this.loadImageProductItem();
      //     this.loadImageGameList(2);
      //     this.loadImageGameList(6);
      //     this.loadImageGameList(7);
      //   }, this.timeout);
      // }
      // else if (route === 'GameLobby' && params.classify === '2') {
      //   window.setTimeout(() => {
      //     this.loadImageGameList(2);
      //     this.loadImageBannerList();
      //     this.loadImagePromotion();
      //     this.loadImageProductItem();
      //     this.loadImageGameList(6);
      //     this.loadImageGameList(7);
      //   }, this.timeout);
      // } else if (route === 'GameLobby' && params.classify === '6') {
      //   window.setTimeout(() => {
      //     this.loadImageGameList(6);
      //     this.loadImageBannerList();
      //     this.loadImagePromotion();
      //     this.loadImageProductItem();
      //     this.loadImageGameList(2);
      //     this.loadImageGameList(7);
      //   }, this.timeout);
      // } else if (route === 'GameLobby' && params.classify === '7') {
      //   window.setTimeout(() => {
      //     this.loadImageGameList(7);
      //     this.loadImageBannerList();
      //     this.loadImagePromotion();
      //     this.loadImageProductItem();
      //     this.loadImageGameList(2);
      //     this.loadImageGameList(6);
      //   }, this.timeout);
      // }
    },
  },
};
