import { checkSite, apiGetDomainInfo, apiPreviewModeSwitch } from '@/api/site';
import { getQueryValueByKey, checkQueryByKeyMultiple } from '@/utils/url';

export async function checkSiteActive(SiteID) {
  const result = await checkSite();
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(result, 'text/html');
  const isBlocked = htmlDoc.querySelector('#KeywordForITDetection') === null;

  if (isBlocked) {
    const requestDataDomainInfo = { SiteID, DomainName: window.location.hostname };
    const resultDomain = await apiGetDomainInfo(requestDataDomainInfo);
    return resultDomain;
    //* 不是空值、回傳值非此網域 => 轉址
    // if (resultDomain.Code === 200 && resultDomain.RetObj !== window.location.hostname) {
    //   return { active: false, redirectDomain: result.RetObj };
    // }
  }
  return {};
}

export async function checkSitePreview() {
  if (checkQueryByKeyMultiple('Port', 'SiteID', 'User', 'PreviewMode')) {
    const requestDataPreview = {
      Port: getQueryValueByKey('Port'),
      SiteID: getQueryValueByKey('SiteID'),
      User: getQueryValueByKey('User'),
      PreviewMode: getQueryValueByKey('PreviewMode'),
    };
    const result = await apiPreviewModeSwitch(requestDataPreview);
    return result;
  }
  return {};
}
