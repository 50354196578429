//* 為了刪除已有 Cookie 的
import { cookieRemoveIsLoggedIn, cookieRemoveVersion, cookieRemoveIsPreview } from '@/utils/cookie';

// cookieRemoveToken();
// cookieRemovePublicKey();
cookieRemoveIsLoggedIn();
cookieRemoveVersion();
cookieRemoveIsPreview();

const tokenKey = 'token';
const publicKeyKey = 'publicKey';
const isLoggedInKey = 'isLoggedIn';
const versionKey = 'version';
const isPreviewKey = 'isPreview';
const gamePointDateKey = 'gamePointDate';
const visitDateKey = 'visitDate';

export function localStorageGetToken() {
  return localStorage.getItem(tokenKey);
}

export function localStorageSetToken(item) {
  return localStorage.setItem(tokenKey, item);
}

export function localStorageRemoveToken() {
  return localStorage.removeItem(tokenKey);
}

export function localStorageGetPublicKey() {
  return localStorage.getItem(publicKeyKey);
}

export function localStorageSetPublicKey(item) {
  return localStorage.setItem(publicKeyKey, item);
}

export function localStorageRemovePublicKey() {
  return localStorage.removeItem(publicKeyKey);
}

export function localStorageGetIsLoggedIn() {
  return localStorage.getItem(isLoggedInKey) === 'true';
}

export function localStorageSetIsLoggedIn(isLoggedIn) {
  return localStorage.setItem(isLoggedInKey, isLoggedIn);
}

export function localStorageRemoveIsLoggedIn() {
  return localStorage.removeItem(isLoggedInKey);
}

export function localStorageGetVersion() {
  return localStorage.getItem(versionKey);
}

export function localStorageSetVersion(version) {
  return localStorage.setItem(versionKey, version);
}

export function localStorageRemoveVersion() {
  return localStorage.removeItem(versionKey);
}

export function localStorageGetIsPreview() {
  return localStorage.getItem(isPreviewKey) === 'true';
}

export function localStorageSetIsPreview(isPreview) {
  return localStorage.setItem(isPreviewKey, isPreview);
}

export function localStorageRemoveIsPreview() {
  return localStorage.removeItem(isPreviewKey);
}

export function localStorageGetGamePointDate() {
  return localStorage.getItem(gamePointDateKey);
}

export function localStorageSetGamePointDate(data) {
  return localStorage.setItem(gamePointDateKey, data);
}

export function localStorageRemoveGamePointDate() {
  return localStorage.removeItem(gamePointDateKey);
}

export function localStorageGetVisitDate() {
  return localStorage.getItem(visitDateKey);
}

export function localStorageSetVisitDate(data) {
  return localStorage.setItem(visitDateKey, data);
}

export function localStorageRemoveVisitDate() {
  return localStorage.removeItem(visitDateKey);
}
